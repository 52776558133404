<template>
  <div>
    <div class="flex flex-col md:flex-row">
      <div class="w-full md:mr-4 md:w-3/4">
        <div class="uk-text-large">Checkout</div>
        <UserQuotables
          :userQuotables="userQuotables"
          :isLoading="isLoading"
          :editable="false"
          :hideSavedForLater="true"
        />
      </div>
      <div class="w-full md:w-1/4 items-center justify-center bg-gray-100 px-2 py-4 border rounded-xl">
        <div class="w-full uk-text-center">
          <div class="uk-text-large" v-if="storefrontStorefront.home_delivery == 1">Selecciona un método de entrega</div>
          <div class="uk-text-large" v-if="storefrontStorefront.display_list_of_directions == 1"><b>Solo disponible para recoger en tienda</b><br><br></div>
          <router-link
            class="uk-button uk-button-primary uk-border-rounded"
            to="/profile"
          >
            Administrar Direcciones
          </router-link>
          <hr>
        </div>
        
        <h5 v-if="storefrontStorefront.display_list_of_directions == 0 && storefrontStorefront.home_delivery == 0">
          <p v-if="addresses_storefront_default"><b>{{addresses_storefront_default.first_name}}
          {{ addresses_storefront_default.last_name }}</b> <br>
          <b>Dirección: </b>{{ addresses_storefront_default.address }}<br>
          <b>Teléfono: </b>{{ addresses_storefront_default.phone }}</p>
          <p v-else><b style="color:red">No existe una direccion por defecto!</b></p>
        </h5>
        <div >
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid" v-if="storefrontStorefront.display_list_of_directions == 1">
            <p v-if="array_address.length == 0 "><b style="color:red">No existen direcciones disponibles!</b></p>
            <a v-else href="" class="ml-4"  @click.prevent="showHide">Ver direcciones disponibles</a>
            <br>
            
            <div v-show="show">
              <ul style="list-style-type: none;">
                <li v-for="address in array_address" :key="address.id">
                  <div
                    class="uk-width-1-1" 
                  >
                    <label
                      ><input
                        class="uk-radio"
                        type="radio"
                        name="shipping-addresses"
                        checked
                        v-model="multipleShippingAddresses"
                        :value="false"
                      />
                      <b> {{ address.first_name }} {{ address.last_name }} </b><br>
                      <b>Dirección: </b> {{ address.address }}<br>
                      <b>Teléfono: </b>  {{ address.phone }}<br><br>
                    </label>
                  </div>
                </li>
              </ul>
              <button  class="uk-button uk-button-primary uk-border-rounded"
                  :disabled="pageNumber === 0" 
                  @click="prevPage">
                  Anterior
              </button>
              <button  class="uk-button uk-button-primary uk-border-rounded"
                  :disabled="pageNumber >= pageTotal -1" 
                  @click="nextPage" style="float:right">
                  Siguiente
              </button>
            </div>
          </div>
        </div>
        <div>
          <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid" v-if="storefrontStorefront.home_delivery == 1">
            <label
              ><input
                class="uk-radio"
                type="radio"
                name="shipping-addresses"
                checked
                v-model="multipleShippingAddresses"
                :value="false"
              />
              Entrega en una dirección</label
            >
            <label
              ><input
                class="uk-radio"
                type="radio"
                name="shipping-addresses"
                v-model="multipleShippingAddresses"
                :value="true"
              />
              Entrega en múltiples direcciones</label
            >
          </div>
        </div>
        <ValidationObserver v-slot="{ invalid }">
          <div uk-grid class="uk-margin-top" v-if="storefrontStorefront.home_delivery == 1">
            <div
              v-for="address in addresses"
              :key="address.id"
              class="uk-width-1-1"
            >
              <div
                class="
                  uk-card uk-card-default uk-padding-small uk-border-rounded
                "
              >
                <label>
                  <div class="uk-flex uk-flex-between">
                    <div class="uk-text-bold uk-text-large">
                      <input
                        v-if="multipleShippingAddresses"
                        class="uk-checkbox"
                        type="checkbox"
                        v-model="model.addresses[address.id].selected"
                      />
                      <input
                        v-else
                        class="uk-radio"
                        type="radio"
                        name="address"
                        v-model="mainAddressId"
                        :value="address.id"
                      />
                      <span class="uk-margin-small-left"
                        >{{ address.first_name }} {{ address.last_name }}</span
                      >
                    </div>

                    <div
                      v-if="address.id in shippingLabels"
                      class="
                        uk-margin-right uk-link-reset uk-flex uk-flex-middle
                      "
                    >
                      <div v-if="pointsIcon">
                        <img :src="pointsIcon" alt="" width="50px" />
                        {{ formatThousands(shippingLabels[address.id].quote) }}
                        {{ pointsName }}
                      </div>
                    </div>
                  </div>
                  <div>
                    <span class="uk-text-bold">Teléfono:</span>
                    {{ address.phone }}
                  </div>
                  <div>
                    <span class="uk-text-bold">Dirección:</span>
                    {{ address.address }}, Zona
                    {{ address.zone }}
                  </div>
                  <div>
                    {{ address.city.name }}, {{ address.city.state.name }},
                    {{ address.city.state.country.spanish }}
                  </div>

                  <div
                    class="uk-margin-top uk-text-bold"
                    v-if="
                      multipleShippingAddresses &&
                      model.addresses[address.id].selected
                    "
                  >
                    <div>Distribución de items</div>

                    <div
                      v-for="(userQuotable, index) in userQuotables"
                      :key="
                        'address-' + address.id + '-userQuotableIndex-' + index
                      "
                      class="uk-margin-small-bottom uk-flex uk-flex-middle"
                    >
                      <input
                        class="
                          uk-width-small
                          uk-input
                          uk-text-center
                          uk-border-rounded
                          background-white
                          uk-margin-small-right
                        "
                        type="number"
                        v-model.number="
                          model.addresses[address.id].redeemables[
                            userQuotable.id
                          ]
                        "
                      />
                      <div
                        :class="{
                          'uk-text-success':
                            addressDistributionStatus[userQuotable.id],
                        }"
                      >
                        de {{ userQuotable.quantity }}
                        -
                        {{
                          userQuotable.quotable.redeemable_type ===
                          "App\\Models\\Product"
                            ? userQuotable.quotable.display_name
                            : userQuotable.quotable.redeemable.product
                                .storefront_redeemables[0].display_name +
                              " - " +
                              userQuotable.quotable.redeemable.value
                        }}
                      </div>
                      <div
                        class="uk-text-success"
                        uk-icon="check"
                        v-if="addressDistributionStatus[userQuotable.id]"
                      ></div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div
            class="uk-text-center"
            v-if="Object.keys(model.addresses).length === 0 "
          >
            <p style="color:red">Aún no has agregado una dirección a tu cuenta</p>
          </div>
          <hr/>
          <div class="uk-text-center uk-margin-top">
            <button
              test="complete-checkout-button"
              class="uk-button uk-button-primary uk-border-rounded"
              :disabled="
                invalid ||
                isLoading ||
                !Object.values(addressDistributionStatus).every((x) => x)
              "
              @click="redeemProducts"
            >
              <span v-if="isLoading" uk-spinner></span>
              <span v-else> Completar compra </span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserQuotables from "@/views/Utils/UserQuotables";
export default {
  name: "Checkout",

  components: {
    UserQuotables
  },

  data() {
    return {
      array_address:{
        type:Array,
        required:true
      },
      size:{
        type:Number,
        required:false,
        default: 3
      },
      pageTotal:100,
      pageNumber: 0,
      show:false,
      addresses_storefront: [],
      addresses_storefront_default: null,
      multipleShippingAddresses: false,
      userQuotables: [],
      isLoading: false,
      addresses: [],
      mainAddressId: null,
      isLoadingShippingQuote: false,
      model: {
        addresses: {},
      },
      shippingLabels: [],
    };
  },

  computed: {
    ...mapGetters(["pointsName", "pointsIcon", "storefrontStorefront"]),
    addressDistributionStatus() {
      return this.userQuotables.reduce((accum, userQuotable) => {
        accum[userQuotable.id] =
          userQuotable.quantity ===
          Object.values(this.model.addresses)
            .filter((address) => address.selected)
            .reduce((accum, address) => {
              return accum + address.redeemables[userQuotable.id];
            }, 0);

        return accum;
      }, {});
    },
  },

  mounted() {
    this.getQuoteInfo();
  },
  methods: {
    ...mapActions(["pointsFetchBalance"]),
    redeemProducts() {
      this.isLoading = true;
      this.axios
        .post("/quotes/redeem", {
          addresses: Object.entries(this.model.addresses).reduce(
            (accum, entry) => {
              if (entry[1].selected) {
                accum[entry[0]] = entry[1].redeemables;
              }

              return accum;
            },
            {}
          ),
        })
        .then(({ data: { redeem_id } }) => {
          this.pointsFetchBalance();
          this.$router.push("/redeem/" + redeem_id);
        })
        .catch(() => {
          this.getQuoteInfo(false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    nextPage(){
        this.pageNumber++;
        console.log(this.pageNumber)
        console.log(this.pageTotal)
        this.paginatedData()
    },
    prevPage(){
      this.pageNumber--;
      this.paginatedData()
    },
    showHide(){
      this.show = !this.show;
    },
    pageCount(){
      let l = this.addresses_storefront.length,
          s = 3;
      this.pageTotal = Math.ceil(l/s);
      return Math.ceil(l/s);
    },
    paginatedData(){
      const start = this.pageNumber * 3,
            end = start + 3;
      return this.array_address = this.addresses_storefront
               .slice(start, end);
    },
    getQuoteInfo(refetchAddresses = true) {
      this.isLoading = true;
      this.axios
        .get("/quotes?saved_for_later=0")
        .then(({ data: { userQuotables } }) => {
          this.userQuotables = userQuotables;
          if (refetchAddresses) {
            this.fetchAddresses();
            this.storeFrontAddresses();
            this.storeFrontDefaultAddresses();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getShippingQuote() {
      this.isLoadingShippingQuote = true;
      this.axios
        .post("store/shipping/quote", {
          destinies: Object.keys(this.model.addresses)
            .filter((addressId) => this.model.addresses[addressId].selected)
            .map((addressId) => ({
              address_id: addressId,
            })),
        })
        .then(({ data: { shippingLabels } }) => {
          this.shippingLabels = shippingLabels;
        })
        .finally(() => {
          this.isLoadingShippingQuote = false;
        });
    },
    fetchAddresses() {
      this.isLoadingAddresses = true;
      this.axios
        .get("/addresses")
        .then(({ data }) => {
          this.addresses = data.addresses;
          this.model.addresses = this.addresses.reduce(
            (accum, address, index) => {
              accum[address.id] = {
                selected: index === 0,
                redeemables: this.userQuotables.reduce(
                  (accum, userQuotable) => {
                    accum[userQuotable.id] = 0;

                    return accum;
                  },
                  {}
                ),
              };

              return accum;
            },
            {}
          );

          if (this.addresses.length) {
            this.mainAddressId = this.addresses[0].id;
          }
        })
        .finally(() => {
          this.isLoadingAddresses = false;
        });
    },
    storeFrontAddresses() {
      this.isLoadingAddresses = true;
      this.axios
        .get("/storefronts/" + this.storefrontStorefront.id)
        .then(({ data }) => {
          this.addresses_storefront = data.addresses;
          this.totalPages = Math.round(parseFloat(this.addresses_storefront.length) / 3)
          this.pageCount()
          this.paginatedData()
        })
        .finally(() => {
          this.isLoadingAddresses = false;
        });
    },
    storeFrontDefaultAddresses() {
      this.isLoadingAddresses = true;
      this.axios
        .get("/storefronts_default/" + this.storefrontStorefront.id)
        .then(({ data }) => {
          if(data){
            this.addresses_storefront_default = data.addresses;
          }
        })
        .finally(() => {
          this.isLoadingAddresses = false;
        });
    },
    updateAddressQuantities() {
      this.addresses.forEach((address) => {
        if (address.id === this.mainAddressId) {
          this.model.addresses[address.id].selected = true;
          this.userQuotables.forEach((userQuotable) => {
            this.model.addresses[address.id].redeemables[userQuotable.id] =
              userQuotable.quantity;
          });
        } else {
          this.model.addresses[address.id].selected = false;
          this.userQuotables.forEach((userQuotable) => {
            this.model.addresses[address.id].redeemables[userQuotable.id] = 0;
          });
        }
      });
    },
  },

  watch: {
    multipleShippingAddresses() {
      this.updateAddressQuantities();
    },
    mainAddressId() {
      this.updateAddressQuantities();
    },
    model: {
      deep: true,
      handler() {
        if (Object.keys(this.model.addresses).length) {
          this.getShippingQuote();
        }
      },
    },
  },
};
</script>
